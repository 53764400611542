/* ==========================================================================
   #ALIGN
   ========================================================================== */

.u-float-left {
  float: left;
}

.u-float-right {
  float: right;
}

.u-clear {
  clear: both;
}

.u-text-center {
  text-align: center;
}

.u-text-left {
  text-align: left;
}

.u-text-right {
  text-align: right;
}

.u-pos {
  &-rel {
    position: relative;
  }

  &-cover {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;
  }
}
