@charset "UTF-8";
/*
  Project: Smith And Jones
  Author: Via Studios
 */
/* Fonts
   ========================================================================== */
/* Breakpoints
   ========================================================================== */
/* Colors
   ========================================================================== */
/* ==========================================================================
   #BOX-SIZING
   ========================================================================== */
/**
 * More sensible default box-sizing:
 * css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice
 */
html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

/* ==========================================================================
   #FONT-FACE
   ========================================================================== */
/**
 * Add font-display: swap to font-face blocks here
 * https://www.zachleat.com/web/comprehensive-webfonts/#font-display
 */
/* ==========================================================================
   #NORMALIZE
   ========================================================================== */
/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
       ========================================================================== */
/**
     * 1. Correct the line height in all browsers.
     * 2. Prevent adjustments of font size after orientation changes in
     *    IE on Windows Phone and in iOS.
     */
html {
  line-height: 1.15;
  /* 1 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
}

/* Sections
       ========================================================================== */
/**
     * Remove the margin in all browsers (opinionated).
     */
body {
  margin: 0;
}

/**
     * Add the correct display in IE 9-.
     */
article,
aside,
footer,
header,
nav,
section {
  display: block;
}

/**
     * Correct the font size and margin on `h1` elements within `section` and
     * `article` contexts in Chrome, Firefox, and Safari.
     */
h1, .u-size--h1, blockquote {
  font-size: 2em;
  margin: 0.67em 0;
}

/* Grouping content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
figcaption,
figure {
  display: block;
}

/**
     * Add the correct margin in IE 8.
     */
figure {
  margin: 1em 40px;
}

/**
     * 1. Add the correct box sizing in Firefox.
     * 2. Show the overflow in Edge and IE.
     */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */
}

/**
     * Add the correct display in IE.
     */
main {
  display: block;
}

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/* Links
       ========================================================================== */
/**
     * 1. Remove the gray background on active links in IE 10.
     * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
     */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */
}

/* Text-level semantics
       ========================================================================== */
/**
     * 1. Remove the bottom border in Chrome 57- and Firefox 39-.
     * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
     */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */
}

/**
     * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
     */
b,
strong {
  font-weight: inherit;
}

/**
     * Add the correct font weight in Chrome, Edge, and Safari.
     */
b,
strong {
  font-weight: bolder;
}

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/**
     * Add the correct font style in Android 4.3-.
     */
dfn {
  font-style: italic;
}

/**
     * Add the correct background and color in IE 9-.
     */
mark {
  background-color: #ff0;
  color: #000;
}

/**
     * Add the correct font size in all browsers.
     */
small {
  font-size: 80%;
}

/**
     * Prevent `sub` and `sup` elements from affecting the line height in
     * all browsers.
     */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/* Embedded content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
audio,
video {
  display: inline-block;
}

/**
     * Add the correct display in iOS 4-7.
     */
audio:not([controls]) {
  display: none;
  height: 0;
}

/**
     * Remove the border on images inside links in IE 10-.
     */
img {
  border-style: none;
}

/**
     * Hide the overflow in IE.
     */
svg:not(:root) {
  overflow: hidden;
}

/* Forms
       ========================================================================== */
/**
     * 1. Change the font styles in all browsers (opinionated).
     * 2. Remove the margin in Firefox and Safari.
     */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */
}

/**
     * Show the overflow in IE.
     */
button {
  overflow: visible;
}

/**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */
button,
select {
  /* 1 */
  text-transform: none;
}

/**
     * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
     *    controls in Android 4.
     * 2. Correct the inability to style clickable types in iOS and Safari.
     */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /**
       * Remove the inner border and padding in Firefox.
       */
  /**
       * Restore the focus styles unset by the previous rule.
       */
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
     * Show the overflow in Edge.
     */
input {
  overflow: visible;
}

/**
     * 1. Add the correct box sizing in IE 10-.
     * 2. Remove the padding in IE 10-.
     */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
}

/**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

/**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
       * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
       */
}

[type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */
}

/**
     * Correct the padding in Firefox.
     */
fieldset {
  padding: 0.35em 0.75em 0.625em;
}

/**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  color: inherit;
  /* 2 */
  white-space: normal;
  /* 1 */
}

/**
     * 1. Add the correct display in IE 9-.
     * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */
}

/**
     * Remove the default vertical scrollbar in IE.
     */
textarea {
  overflow: auto;
}

/* Interactive
       ========================================================================== */
/*
     * Add the correct display in Edge, IE, and Firefox.
     */
details {
  display: block;
}

/*
     * Add the correct display in all browsers.
     */
summary {
  display: list-item;
}

/*
     * Add the correct display in IE 9-.
     */
menu {
  display: block;
}

/* Scripting
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
canvas {
  display: inline-block;
}

/**
     * Add the correct display in IE.
     */
template {
  display: none;
}

/* Hidden
       ========================================================================== */
/**
     * Add the correct display in IE 10-.
     */
[hidden] {
  display: none;
}

/* ==========================================================================
   #RESET
   ========================================================================== */
/**
 * A very simple reset that sits on top of Normalize.css.
 */
body, h1, .u-size--h1, blockquote, h2, .u-size--h2, h3, .u-size--h3, h4, .u-size--h4, h5, .u-size--h5, h6, .u-size--h6,
blockquote, p, pre,
dl, dd, ol, ul,
figure,
hr,
fieldset, legend {
  margin: 0;
  padding: 0;
}

/**
 * Remove trailing margins from nested lists.
 */
li > ol,
li > ul {
  margin-bottom: 0;
}

/**
 * Remove default table spacing.
 */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/**
 * 1. Reset Chrome and Firefox behaviour which sets a `min-width: min-content;`
 *    on fieldsets.
 */
fieldset {
  min-width: 0;
  /* [1] */
  border: 0;
}

/* ==========================================================================
   #SHARED
   ========================================================================== */
/**
 * Shared declarations for certain elements.
 */
/**
 * Always declare margins in the same direction:
 * csswizardry.com/2012/06/single-direction-margin-declarations
 */
address, h1, .u-size--h1, blockquote, h2, .u-size--h2, h3, .u-size--h3, h4, .u-size--h4, h5, .u-size--h5, h6, .u-size--h6,
blockquote, p, pre,
dl, ol, ul,
figure,
hr,
table,
fieldset {
  margin-bottom: 1.3rem;
}

/**
 * Consistent indentation for lists.
 */
dd, ol, ul {
  margin-left: 1rem;
}

/* ==========================================================================
   #BLOCKQUOTES
   ========================================================================== */
blockquote {
  quotes: '“' '”' '‘' '’';
}

blockquote p:first-child:before {
  content: open-quote;
}

blockquote p:last-child:after {
  content: close-quote;
}

blockquote:before {
  background: #f4b7d2;
  display: block;
  content: '';
  height: 3px;
  width: 50%;
  max-width: 400px;
  margin-bottom: 1rem;
}

/* ==========================================================================
   #HEADINGS
   ========================================================================== */
/**
 * Simple default styles for headings 1 through 6. Anything more opinionated
 * than simple font-size changes should likely be applied via classes (see:
 * http://csswizardry.com/2016/02/managing-typography-on-large-apps/).
 */
h1, .u-size--h1, blockquote {
  font-weight: 500;
  font-size: 2.25rem;
  line-height: 1.1;
}

h2, .u-size--h2 {
  font-weight: 500;
  font-size: 1.75rem;
  line-height: 1.2;
}

h3, .u-size--h3 {
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.3;
}

h4, .u-size--h4 {
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 1.4;
}

h5, .u-size--h5 {
  font-weight: 400;
  font-size: 1.125rem;
}

h6, .u-size--h6 {
  font-weight: 400;
  font-size: 1rem;
}

/* ==========================================================================
   #HR
   ========================================================================== */
hr {
  display: block;
}

/* ==========================================================================
   #PAGE
   ========================================================================== */
/**
 * Simple page-level setup.
 *
 * 1. Set the default `font-size` and `line-height` for the entire project.
 * 2. Force scrollbars to always be visible to prevent awkward ‘jumps’ when
 *    navigating between pages that do/do not have enough content to produce
 *    scrollbars naturally.
 * 3. Ensure the page always fills at least the entire height of the viewport.
 */
html {
  background: #000;
  color: #fff;
  font-family: "gill-sans-nova", "Helvetica Neue", "Arial", sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  /* [1] */
  /* 13px */
  line-height: 1.5;
  /* [1] */
  min-height: 100%;
  /* [3] */
  overflow-y: scroll;
  /* [2] */
  overflow-x: hidden;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

@media (min-width: 768px) {
  html {
    font-size: 1em;
    /* 16px */
  }
}

@media (min-width: 1024px) {
  html {
    font-size: 1.0625em;
    /* 17px */
  }
}

@media (min-width: 1600px) {
  html {
    font-size: 1.125em;
    /* 18px */
  }
}

/* ==========================================================================
   #IMAGES
   ========================================================================== */
/**
 * 1. Fluid images for responsive purposes.
 * 2. Offset `alt` text from surrounding copy.
 * 3. Setting `vertical-align` removes the whitespace that appears under `img`
 *    elements when they are dropped into a page as-is. Safer alternative to
 *    using `display: block;`.
 */
img {
  font-style: italic;
  /* [2] */
  max-width: 100%;
  /* [1] */
  vertical-align: middle;
  /* [3] */
}

/**
 * 1. If a `width` and/or `height` attribute have been explicitly defined, let’s
 *    not make the image fluid.
 */
img[width],
img[height] {
  max-width: none;
}

/* ==========================================================================
   #LINKS
   ========================================================================== */
a {
  color: #fff;
  text-decoration: underline;
}

a:hover {
  color: #e96ca3;
}

/* ==========================================================================
   #LISTS
   ========================================================================== */
li {
  margin-bottom: 0.5rem;
}

li > ul,
li > ol {
  margin-bottom: 0;
  margin-left: 1rem;
  margin-top: 0.5rem;
}

dt {
  font-weight: bold;
}

dd {
  margin-bottom: 1rem;
  margin-left: 1rem;
}

/* ==========================================================================
   #TABLES
   ========================================================================== */
/**
 * 1. Ensure tables fill up as much space as possible.
 */
table {
  width: 100%;
  /* [1] */
}

/* ==========================================================================
   #VIDEOS
   ========================================================================== */
/**
 * 1. Fluid images for responsive purposes.
 */
video {
  max-width: 100%;
  /* [1] */
}

/**
 * 1. If a `width` and/or `height` attribute have been explicitly defined, let’s
 *    not make the image fluid.
 */
video[width],
video[height] {
  max-width: none;
}

video:hover,
video:focus,
video:active {
  outline: none;
}

/* ==========================================================================
   #GRID
   ========================================================================== */
.o-grid {
  display: grid;
  grid-gap: 30px;
  grid-template-columns: 1fr;
}

@media (min-width: 480px) {
  .o-grid {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media (min-width: 480px) {
  .o-grid--2 {
    grid-template-columns: 1fr 1fr;
  }
}

@media (min-width: 480px) {
  .o-grid--1 {
    grid-template-columns: 1fr;
  }
}

@media (min-width: 480px) {
  .o-grid__body-right, .c-footer aside:last-child {
    grid-column: 2 / 4;
  }
}

@media (min-width: 480px) {
  .o-grid__second-col {
    grid-column: 2 / 3;
  }
}

@media (min-width: 480px) {
  .o-grid__third-col {
    grid-column: 3 / 4;
  }
}

@media (min-width: 480px) {
  .o-grid--stagger > *:nth-child(odd) {
    margin-top: 15vh;
  }
}

/* ==========================================================================
   #LAYOUT
   ========================================================================== */
/* Simple flexbox layout system */
.o-layout {
  display: block;
}

@media (min-width: 768px) {
  .o-layout {
    display: flex;
  }
}

.o-layout__item {
  flex: 1;
}

.o-layout--guttered {
  justify-content: space-between;
}

.o-layout--guttered > .o-layout__item {
  flex: none;
}

@media (min-width: 768px) {
  .o-layout--2 > .o-layout__item {
    width: 49%;
  }
}

@media (min-width: 768px) {
  .o-layout--3 > .o-layout__item {
    width: 32%;
  }
}

@media (min-width: 768px) {
  .o-layout--4 > .o-layout__item {
    width: 24%;
  }
}

/* ==========================================================================
   #LIST-BARE
   ========================================================================== */
/**
 * Strip list-like appearance from lists by removing their bullets, and any
 * indentation.
 */
.o-list-bare {
  list-style: none;
  margin-left: 0;
}

/* ==========================================================================
   #LIST-INLINE
   ========================================================================== */
/**
 * The list-inline object simply displays a list of items in one line.
 */
.o-list-inline {
  list-style: none;
  margin-left: 0;
}

.o-list-inline__item {
  display: inline-block;
}

/* ==========================================================================
   #MEDIA
   ========================================================================== */
/**
 * Place any image- and text-like content side-by-side, as per:
 * http://www.stubbornella.org/content/2010/06/25/the-media-object-saves-hundreds-of-lines-of-code
 */
.o-media {
  display: block;
}

.o-media:after {
  content: '' !important;
  display: block !important;
  clear: both !important;
}

.o-media__img {
  float: left;
  margin-right: 1rem;
}

.o-media__img > img {
  display: block;
}

.o-media__body {
  display: block;
  overflow: hidden;
}

.o-media__body,
.o-media__body > :last-child {
  margin-bottom: 0;
}

/* Reversed media objects
   ========================================================================== */
.o-media--reverse > .o-media__img {
  float: right;
  margin-left: 1rem;
  margin-right: 0;
}

/* Gutterless media objects
   ========================================================================== */
.o-media--flush > .o-media__img {
  margin-left: 0;
  margin-right: 0;
}

/* ==========================================================================
   #TABLE
   ========================================================================== */
/**
 * A simple object for manipulating the structure of HTML `table`s.
 */
.o-table {
  width: 100%;
}

/* Equal-width table cells.
   ========================================================================== */
/**
 * `table-layout: fixed` forces all cells within a table to occupy the same
 * width as each other. This also has performance benefits: because the browser
 * does not need to (re)calculate cell dimensions based on content it discovers,
 * the table can be rendered very quickly. Further reading:
 * https://developer.mozilla.org/en-US/docs/Web/CSS/table-layout#Values
 */
.o-table--fixed {
  table-layout: fixed;
}

/* ==========================================================================
   #WRAPPER
   ========================================================================== */
/**
 * Page-level constraining and wrapping elements.
 */
.o-wrapper {
  margin-right: auto;
  margin-left: auto;
  max-width: 1100px;
  padding: 0 30px;
}

.o-wrapper:after {
  content: '' !important;
  display: block !important;
  clear: both !important;
}

/* ==========================================================================
   #ACCORDIAN
   ========================================================================== */
.c-accordian {
  text-align: right;
}

.c-accordian [type='checkbox'] {
  display: none;
}

.c-accordian__label {
  display: block;
}

.c-accordian__body {
  text-align: left;
  height: 0;
  overflow: hidden;
  transition: all 300ms ease-in-out;
  margin-top: 2rem;
}

[type='checkbox']:checked ~ .c-accordian__body {
  height: initial;
  transform: translateY(0);
  opacity: 1;
}

.c-accordian--clip .c-accordian__body {
  transition: all 300ms ease-in-out;
  transform: translateY(1rem);
  opacity: 0;
}

.c-accordian--reel {
  margin-top: 2rem;
  position: relative;
  width: 100%;
}

.c-accordian--reel .c-accordian__label {
  top: 0;
}

.c-accordian--reel .c-accordian__body {
  white-space: initial;
}

/* ==========================================================================
   #BUTTONS
   ========================================================================== */
/**
 * This is an example component. Extend inuitcss by building your own components
 * that make up your UI. Component classes are prefixed with a `c-`.
 */
/**
 * 1. Allow us to style box model properties.
 * 2. Line different sized buttons up a little nicer.
 * 3. Make buttons inherit font styles (often necessary when styling `input`s as
 *    buttons).
 * 4. Reset/normalize some styles.
 * 5. Force all button-styled elements to appear clickable.
 */
.c-btn {
  display: inline-block;
  /* [1] */
  vertical-align: middle;
  /* [2] */
  font: inherit;
  /* [3] */
  text-align: center;
  /* [4] */
  margin: 0;
  /* [4] */
  cursor: pointer;
  /* [5] */
  padding: 0.5rem 1rem;
  transition: all 300ms ease-in-out;
  border-radius: 3px;
}

/* Style variants
   ========================================================================== */
.c-btn--primary {
  background-color: #fff;
}

.c-btn--primary, .c-btn--primary:hover, .c-btn--primary:active, .c-btn--primary:focus {
  text-decoration: none;
  /* [4] */
  color: #fff;
}

.c-btn--primary:hover, .c-btn--primary:focus {
  background-color: #e96ca3;
}

/* Size variants
   ========================================================================== */
.c-btn--small {
  padding: 0.25rem 0.5rem;
}

.c-btn--large {
  padding: 0.75rem 1.5rem;
}

/* ==========================================================================
   #CURSOR
   ========================================================================== */
.c-cursor {
  background: transparent;
  position: fixed;
  box-sizing: border-box;
  pointer-events: none;
  border-radius: 50%;
  z-index: 1000;
  height: 18px;
  width: 18px;
  border: 2px solid #e96ca3;
  transition: height 0.15s ease-in, width 0.15s ease-in, margin 0.15s ease-in;
}

.js-active-hover .c-cursor {
  width: 32px;
  height: 32px;
  margin: -7px 0 0 -7px;
}

/* ==========================================================================
   #FOOTER
   ========================================================================== */
.c-footer {
  position: relative;
  background: #f4b7d2;
  color: #000;
  padding: 12.5vh 0;
  z-index: 20;
}

/* ==========================================================================
   #HEADER
   ========================================================================== */
.c-header__h1 {
  margin: 0;
}

.c-header__logo {
  position: absolute;
  z-index: 10;
  top: 30px;
  left: 30px;
  display: block;
  width: 30vw;
  height: 25vw;
  max-width: 250px;
  max-height: 200px;
}

/* ==========================================================================
   #MAIN NAV
   ========================================================================== */
.c-main-nav {
  left: calc(100% - 85px);
  list-style-type: none;
  position: absolute;
  z-index: 10;
  padding: 0;
  margin: 0;
  top: 2rem;
}

@media (min-width: 768px) {
  .c-main-nav {
    transform: translateY(calc(-100% - 30px));
    position: fixed;
    top: 100%;
    left: 30px;
  }
}

.c-main-nav__item {
  color: #f4b7d2;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
}

@media (min-width: 768px) {
  .c-main-nav__item {
    margin-bottom: 1rem;
  }
}

.c-main-nav__item:hover .c-main-nav__dropdown {
  display: flex;
}

.c-main-nav__link {
  transition: all 300ms ease-in-out;
  text-decoration: none;
  background: transparent;
  position: relative;
  color: #f4b7d2;
  padding: 6px 0;
}

.c-main-nav__link:hover {
  padding: 6px;
  color: #000;
}

.current-menu-item .c-main-nav__link {
  color: #e96ca3;
}

.current-menu-item .c-main-nav__link:before {
  content: '\2014';
  margin-right: 5px;
}

.c-main-nav__dropdown {
  display: none;
  flex-direction: column;
  margin: 0;
  position: absolute;
}

.c-main-nav__subitem {
  list-style: none;
  margin: 0;
}

.c-main-nav__sublink {
  background: #fff;
  border-bottom: 1px solid #ccc;
  display: block;
  padding: 0.5em;
  text-decoration: none;
}

.current-menu-item .c-main-nav__sublink {
  border-bottom-color: #000;
}

/* ==========================================================================
   #POST
   Includes styles for default WP images classes
   ========================================================================== */
.c-post {
  min-height: 100vh;
  padding-bottom: 150px;
  padding-top: 225px;
}

@media (min-width: 768px) {
  .c-post {
    padding-top: 150px;
  }
}

.c-post__title {
  margin: 0;
}

.c-post__background {
  position: fixed !important;
  height: 100vh;
  width: 100vw;
  object-fit: cover;
  z-index: -1;
}

.c-post__background--video {
  opacity: 0.15 !important;
}

.c-post__background--image:before {
  content: '';
  display: block;
  z-index: 1;
  background-image: radial-gradient(ellipse at 90% 20%, rgba(0, 0, 0, 0.3), black 115vw);
}

.c-post__background--image .c-post__thumbnail {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.alignnone {
  margin: 1em 1em 1em 0;
}

.aligncenter {
  margin: 1em auto;
}

.alignright {
  float: right;
  margin: 1em 0 1em 1em;
}

.alignleft {
  float: left;
  margin: 1em 1em 1em 0;
}

.wp-caption {
  text-align: center;
}

.wp-caption-text {
  font-style: italic;
  margin-top: 0.5em;
}

/* ==========================================================================
   #REEL PAGES
   ========================================================================== */
.c-reel {
  min-height: 100vh;
}

.c-reel__frame {
  overflow-x: hidden;
  white-space: nowrap;
  padding: 225px 0 50px;
}

@media (min-width: 768px) {
  .c-reel__frame {
    padding: 135px 0;
  }
}

.c-reel__item {
  position: relative;
  display: inline-block;
  width: 100vw;
  vertical-align: top;
  padding: 0 30px;
}

.c-reel__container {
  margin: 0 auto;
  max-width: 960px;
  width: 100%;
}

.c-reel__thumbs {
  display: grid;
  padding: 0.5rem 30px;
  background: #000;
  transition: all 300ms ease-in-out;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1rem;
}

@media (min-width: 480px) {
  .c-reel__thumbs {
    z-index: 10;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    max-width: 250px;
    height: 100vh;
    width: 50%;
    transform: translateX(60%);
    position: absolute;
    top: 0;
    right: 0;
  }
}

.c-reel__thumbs:hover, .c-reel__thumbs.js-display-thumbs {
  transform: translateX(0);
}

.c-reel__thumbs:hover .c-reel__thumb:not(.active), .c-reel__thumbs.js-display-thumbs .c-reel__thumb:not(.active) {
  opacity: 1;
}

.c-reel__thumb {
  transition: all 300ms ease-in-out;
  opacity: 0.35;
  margin: 0.5rem 0;
}

.c-reel__thumb--archive-link {
  position: relative;
  padding-top: 56.25%;
  background: #464646;
}

.c-reel__archive-link {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  text-decoration: none;
  justify-content: center;
}

.active.c-reel__thumb {
  opacity: 0.35;
}

/* ==========================================================================
   #SLIDER
   ========================================================================== */
.c-slider {
  width: 100%;
  min-height: 100vh;
}

.c-slider__slides {
  margin: 0;
}

.c-slider__slide {
  margin: 0;
  position: relative;
  display: none;
}

.c-slider__video {
  object-fit: cover;
  height: 100vh;
  width: 100vw;
}

.c-slider__detail {
  transition: background-image 1s ease-in;
  z-index: 1;
  background-image: radial-gradient(ellipse at 90% 20%, rgba(0, 0, 0, 0), black 115vw);
}

.c-slider__body {
  height: 100vh;
}

.c-slider__campaign {
  margin: 0;
}

.c-slider__campaign > span {
  margin-right: 1px;
  will-change: transform;
  display: inline-block;
  white-space: pre;
}

.c-slider__campaign > p:last-child {
  margin-bottom: 0;
}

.c-slider__skip {
  transition: all 300ms ease-in-out;
  position: absolute;
  display: block;
  bottom: 30px;
  right: 30px;
  z-index: 10;
  padding: 10px;
  border-radius: 50%;
}

.c-slider__skip:after {
  border-radius: 50% !important;
}

.c-slider__slide--active {
  display: block;
}

.c-slider__slide--active .c-slider__title:nth-child(2) {
  animation-delay: 0.3s;
}

.c-table--scroll, .c-table--scroll-small {
  height: 500px;
  display: block;
  background: rgba(255, 255, 255, 0.075);
}

.c-table--scroll tbody, .c-table--scroll-small tbody {
  width: 100%;
  max-width: 683px;
  height: 500px;
  padding: 1rem 2rem;
  position: absolute;
  overflow: scroll;
}

.c-table--scroll td, .c-table--scroll-small td {
  min-width: 400px;
  padding: 1rem 0;
}

.c-table--scroll tr, .c-table--scroll-small tr {
  padding: 2px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.c-table--scroll tr:last-child, .c-table--scroll-small tr:last-child {
  border-bottom: none;
}

.c-table--scroll tr > td:nth-child(2), .c-table--scroll-small tr > td:nth-child(2) {
  min-width: 100px;
}

.c-table--scroll tr > td:last-child, .c-table--scroll-small tr > td:last-child {
  min-width: 350px;
}

.c-table--scroll-small {
  height: 100px;
}

.c-table--scroll-small tbody {
  height: 100px;
}

/* ==========================================================================
   #ALIGN
   ========================================================================== */
.u-float-left {
  float: left;
}

.u-float-right {
  float: right;
}

.u-clear {
  clear: both;
}

.u-text-center {
  text-align: center;
}

.u-text-left {
  text-align: left;
}

.u-text-right {
  text-align: right;
}

.u-pos-rel {
  position: relative;
}

.u-pos-cover, .c-post__background--image:before, .c-slider__detail, .u-link-highlight:after, .c-main-nav__link:after, .c-slider__skip:after {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
}

/* ==========================================================================
   #ANIMATIONS
   ========================================================================== */
@keyframes bounce {
  0%,
  20%,
  53%,
  80%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translateZ(0);
  }
  40%,
  43% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -20px, 0);
  }
  70% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -10px, 0);
  }
  90% {
    transform: translate3d(0, -2px, 0);
  }
}

@keyframes bounce-right {
  0%,
  20%,
  53%,
  80%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translateZ(0);
  }
  40%,
  43% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(-15px, 0, 0);
  }
  70% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(-7px, 0, 0);
  }
  90% {
    transform: translate3d(-2px, 0, 0);
  }
}

@keyframes fade-scale-up {
  from {
    transition-timing-function: ease-in;
    transform: scale(0.98) translate(2vh, -2vw);
    opacity: 0;
  }
  to {
    transition-timing-function: ease-out;
    transform: scale(1) translate(0, 0);
    opacity: 1;
  }
}

@keyframes fade-slide-up {
  from {
    transition-timing-function: ease-in;
    transform: translateY(40px);
    opacity: 0;
  }
  to {
    transition-timing-function: ease-out;
    transform: translateY(0);
    opacity: 1;
  }
}

.u-animated, .c-reel__video, .c-slider__video, .c-slider__title {
  animation-duration: 2s;
  animation-fill-mode: both;
}

.u-animated--infinite {
  animation-iteration-count: infinite;
}

.u-bounce {
  animation-name: bounce;
  transform-origin: center bottom;
}

.u-bounce--right {
  animation-name: bounce-right;
  transform-origin: center bottom;
}

.u-fade-scale-up, .active .c-reel__video, .c-slider__slide--active .c-slider__video {
  animation-name: fade-scale-up;
  transform-origin: top right;
}

.u-fade-slide-up, .c-slider__slide--active .c-slider__title {
  animation-duration: 1.25s;
  animation-name: fade-slide-up;
  transform-origin: center bottom;
}

/* ==========================================================================
   #BACKGROUND
   ========================================================================== */
.u-background--gradient {
  background-image: radial-gradient(ellipse at 90% 20%, rgba(0, 0, 0, 0), black 115vw);
}

/* ==========================================================================
   #CLEARFIX
   ========================================================================== */
/**
 * Attach our clearfix mixin to a utility class.
 */
.u-clearfix:after {
  content: '' !important;
  display: block !important;
  clear: both !important;
}

/* ==========================================================================
   #COLOURS
   ========================================================================== */
.u-color--pink {
  color: #f4b7d2;
  fill: #f4b7d2;
}

.u-color--black {
  color: #000;
}

/* ==========================================================================
   #FLEX CLASSES
   ========================================================================== */
.u-flex {
  display: flex;
}

.u-flex--vcenter {
  flex-direction: column;
  justify-content: center;
}

/* ==========================================================================
   #HIDE
   ========================================================================== */
/**
 * Hide only visually, but have it available for screen readers:
 * http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 */
.u-hidden-visually {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  white-space: nowrap !important;
  width: 1px !important;
}

/**
 * Hide visually and from screen readers.
 */
.u-hidden {
  display: none !important;
}

.u-hidden\@small {
  display: none;
}

@media (min-width: 768px) {
  .u-hidden\@small {
    display: block;
  }
}

/* ==========================================================================
   #LINK CLASSES
   ========================================================================== */
.u-link-highlight:after, .c-main-nav__link:after, .c-slider__skip:after {
  border-radius: 3px;
  transition: all 300ms ease-in-out;
  margin: 6px;
  display: block;
  content: '';
  z-index: -1;
}

.u-link-highlight:hover:after, .c-main-nav__link:hover:after, .c-slider__skip:hover:after {
  background: #f4b7d2;
  margin: 0;
}

/* ==========================================================================
   #SPACINGS
   ========================================================================== */
/**
 * Utility classes to put specific spacing values onto elements. The below loop
 * will generate us a suite of classes like:
 *
 *   .u-margin-top {}
 *   .u-padding-left-large {}
 *   .u-margin-right-small {}
 *   .u-padding {}
 *   .u-padding-right-none {}
 *   .u-padding-horizontal {}
 *   .u-padding-vertical-small {}
 */
/* stylelint-disable string-quotes */
.u-padding {
  padding: 1rem !important;
}

.u-padding-tiny {
  padding: 0.25rem !important;
}

.u-padding-small {
  padding: 0.5rem !important;
}

.u-padding-large {
  padding: 2rem !important;
}

.u-padding-huge {
  padding: 4rem !important;
}

.u-padding-none {
  padding: 0 !important;
}

.u-padding-top {
  padding-top: 1rem !important;
}

.u-padding-top-tiny {
  padding-top: 0.25rem !important;
}

.u-padding-top-small {
  padding-top: 0.5rem !important;
}

.u-padding-top-large {
  padding-top: 2rem !important;
}

.u-padding-top-huge {
  padding-top: 4rem !important;
}

.u-padding-top-none {
  padding-top: 0 !important;
}

.u-padding-right {
  padding-right: 1rem !important;
}

.u-padding-right-tiny {
  padding-right: 0.25rem !important;
}

.u-padding-right-small {
  padding-right: 0.5rem !important;
}

.u-padding-right-large {
  padding-right: 2rem !important;
}

.u-padding-right-huge {
  padding-right: 4rem !important;
}

.u-padding-right-none {
  padding-right: 0 !important;
}

.u-padding-bottom {
  padding-bottom: 1rem !important;
}

.u-padding-bottom-tiny {
  padding-bottom: 0.25rem !important;
}

.u-padding-bottom-small {
  padding-bottom: 0.5rem !important;
}

.u-padding-bottom-large {
  padding-bottom: 2rem !important;
}

.u-padding-bottom-huge {
  padding-bottom: 4rem !important;
}

.u-padding-bottom-none {
  padding-bottom: 0 !important;
}

.u-padding-left {
  padding-left: 1rem !important;
}

.u-padding-left-tiny {
  padding-left: 0.25rem !important;
}

.u-padding-left-small {
  padding-left: 0.5rem !important;
}

.u-padding-left-large {
  padding-left: 2rem !important;
}

.u-padding-left-huge {
  padding-left: 4rem !important;
}

.u-padding-left-none {
  padding-left: 0 !important;
}

.u-padding-horizontal {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.u-padding-horizontal-tiny {
  padding-left: 0.25rem !important;
  padding-right: 0.25rem !important;
}

.u-padding-horizontal-small {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}

.u-padding-horizontal-large {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}

.u-padding-horizontal-huge {
  padding-left: 4rem !important;
  padding-right: 4rem !important;
}

.u-padding-horizontal-none {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.u-padding-vertical {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.u-padding-vertical-tiny {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.u-padding-vertical-small {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.u-padding-vertical-large {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.u-padding-vertical-huge {
  padding-top: 4rem !important;
  padding-bottom: 4rem !important;
}

.u-padding-vertical-none {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.u-margin {
  margin: 1rem !important;
}

.u-margin-tiny {
  margin: 0.25rem !important;
}

.u-margin-small {
  margin: 0.5rem !important;
}

.u-margin-large {
  margin: 2rem !important;
}

.u-margin-huge {
  margin: 4rem !important;
}

.u-margin-none {
  margin: 0 !important;
}

.u-margin-top {
  margin-top: 1rem !important;
}

.u-margin-top-tiny {
  margin-top: 0.25rem !important;
}

.u-margin-top-small {
  margin-top: 0.5rem !important;
}

.u-margin-top-large {
  margin-top: 2rem !important;
}

.u-margin-top-huge {
  margin-top: 4rem !important;
}

.u-margin-top-none {
  margin-top: 0 !important;
}

.u-margin-right {
  margin-right: 1rem !important;
}

.u-margin-right-tiny {
  margin-right: 0.25rem !important;
}

.u-margin-right-small {
  margin-right: 0.5rem !important;
}

.u-margin-right-large {
  margin-right: 2rem !important;
}

.u-margin-right-huge {
  margin-right: 4rem !important;
}

.u-margin-right-none {
  margin-right: 0 !important;
}

.u-margin-bottom {
  margin-bottom: 1rem !important;
}

.u-margin-bottom-tiny {
  margin-bottom: 0.25rem !important;
}

.u-margin-bottom-small {
  margin-bottom: 0.5rem !important;
}

.u-margin-bottom-large {
  margin-bottom: 2rem !important;
}

.u-margin-bottom-huge {
  margin-bottom: 4rem !important;
}

.u-margin-bottom-none {
  margin-bottom: 0 !important;
}

.u-margin-left {
  margin-left: 1rem !important;
}

.u-margin-left-tiny {
  margin-left: 0.25rem !important;
}

.u-margin-left-small {
  margin-left: 0.5rem !important;
}

.u-margin-left-large {
  margin-left: 2rem !important;
}

.u-margin-left-huge {
  margin-left: 4rem !important;
}

.u-margin-left-none {
  margin-left: 0 !important;
}

.u-margin-horizontal {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

.u-margin-horizontal-tiny {
  margin-left: 0.25rem !important;
  margin-right: 0.25rem !important;
}

.u-margin-horizontal-small {
  margin-left: 0.5rem !important;
  margin-right: 0.5rem !important;
}

.u-margin-horizontal-large {
  margin-left: 2rem !important;
  margin-right: 2rem !important;
}

.u-margin-horizontal-huge {
  margin-left: 4rem !important;
  margin-right: 4rem !important;
}

.u-margin-horizontal-none {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.u-margin-vertical {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.u-margin-vertical-tiny {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.u-margin-vertical-small {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.u-margin-vertical-large {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

.u-margin-vertical-huge {
  margin-top: 4rem !important;
  margin-bottom: 4rem !important;
}

.u-margin-vertical-none {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

/* ==========================================================================
   #SIMPLE TYPOGRAPHIC CLASSES
   ========================================================================== */
.u-link--naked {
  text-decoration: none;
}

.u-link--dots {
  text-decoration-style: dotted;
}

.u-size--p {
  font-size: 1rem;
}

.u-weight--light, blockquote {
  font-weight: 300;
}

.u-weight--regular {
  font-weight: 400;
}

.u-weight--medium {
  font-weight: 500;
}

.u-opacity--half {
  opacity: 0.5;
}

.u-title-grouping {
  line-height: 1rem;
}

.u-subtitle {
  text-transform: uppercase;
  letter-spacing: 1px;
  display: block;
}

.u-text-cols {
  columns: 2 200px;
  column-gap: 30px;
}
