/* ==========================================================================
   #HEADER
   ========================================================================== */

.c-header {
  &__h1 {
    margin: 0;
  }

  &__logo {
    position: absolute;
    z-index: 10;
    top: 30px;
    left: 30px;
    display: block;
    width: 30vw;
    height: 25vw;
    max-width: 250px;
    max-height: 200px;
  }
}
