/* ==========================================================================
   #ACCORDIAN
   ========================================================================== */

.c-accordian {
  text-align: right;

  [type='checkbox'] {
    display: none;
  }

  &__label {
    display: block;
  }

  &__body {
    text-align: left;
    height: 0;
    overflow: hidden;
    transition: $global-transition;
    margin-top: 2rem;
  }
}

[type='checkbox']:checked ~ .c-accordian__body {
  height: initial;
  transform: translateY(0);
  opacity: 1;
}

.c-accordian--clip {
  .c-accordian__body {
    transition: $global-transition;
    transform: translateY(1rem);
    opacity: 0;
  }
}

.c-accordian--reel {
  margin-top: 2rem;
  position: relative;
  width: 100%;

  .c-accordian__label {
    top: 0;
  }

  .c-accordian__body {
    white-space: initial;
  }
}
