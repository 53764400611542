/* ==========================================================================
   #BACKGROUND
   ========================================================================== */

.u-background {
  &--gradient {
    background-image: radial-gradient(
      ellipse at 90% 20%,
      rgba(0, 0, 0, 0),
      rgb(0, 0, 0) 115vw
    );
  }
}
