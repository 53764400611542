/* ==========================================================================
   #POST
   Includes styles for default WP images classes
   ========================================================================== */

.c-post {
  min-height: 100vh;
  padding-bottom: 150px;
  padding-top: 225px;

  @include bp(medium) {
    padding-top: 150px;
  }

  &__title {
    margin: 0;
  }

  &__background {
    position: fixed !important;
    height: 100vh;
    width: 100vw;
    object-fit: cover;
    z-index: -1;
  }

  &__background--video {
    opacity: 0.15 !important;
  }

  &__background--image {
    &:before {
      @extend .u-pos-cover;
      content: '';
      display: block;
      z-index: 1;
      background-image: radial-gradient(
        ellipse at 90% 20%,
        rgba(0, 0, 0, 0.3),
        rgb(0, 0, 0) 115vw
      );
    }

    .c-post__thumbnail {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}

.alignnone {
  margin: 1em 1em 1em 0;
}

.aligncenter {
  margin: 1em auto;
}

.alignright {
  float: right;
  margin: 1em 0 1em 1em;
}

.alignleft {
  float: left;
  margin: 1em 1em 1em 0;
}

.wp-caption {
  text-align: center;
}

.wp-caption-text {
  font-style: italic;
  margin-top: 0.5em;
}
