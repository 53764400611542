/* ==========================================================================
   #FOOTER
   ========================================================================== */

.c-footer {
  position: relative;
  background: $color-pink;
  color: $color-black;
  padding: 12.5vh 0;
  z-index: 20;

  aside:last-child {
    @extend .o-grid__body-right;
  }
}
