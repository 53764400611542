.c-table--scroll {
  height: 500px;
  display: block;
  background: rgba(255, 255, 255, 0.075);

  tbody {
    width: 100%;
    max-width: 683px;
    height: 500px;
    padding: 1rem 2rem;
    position: absolute;
    overflow: scroll;
  }

  td {
    min-width: 400px;
    padding: 1rem 0;
  }

  tr {
    padding: 2px 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);

    &:last-child {
      border-bottom: none;
    }
  }

  tr > td {
    &:nth-child(2) {
      min-width: 100px;
    }

    &:last-child {
      min-width: 350px;
    }
  }
}

.c-table--scroll-small {
  @extend .c-table--scroll;
  height: 100px;

  tbody {
    height: 100px;
  }
}
