/* ==========================================================================
   #COLOURS
   ========================================================================== */

.u-color {
  &--pink {
    color: $color-pink;
    fill: $color-pink;
  }

  &--black {
    color: $color-black;
  }
}
