/* ==========================================================================
   #CURSOR
   ========================================================================== */

.c-cursor {
  background: transparent;
  position: fixed;
  box-sizing: border-box;
  pointer-events: none;
  border-radius: 50%;
  z-index: 1000;
  height: 18px;
  width: 18px;
  border: 2px solid $color-pink--dark;
  transition: height 0.15s ease-in, width 0.15s ease-in, margin 0.15s ease-in;
}

// Hover state passes down from body class
.js-active-hover .c-cursor {
  width: 32px;
  height: 32px;
  margin: -7px 0 0 -7px;
}
