/* ==========================================================================
   #SIMPLE TYPOGRAPHIC CLASSES
   ========================================================================== */

.u-link {
  &--naked {
    text-decoration: none;
  }

  &--dots {
    text-decoration-style: dotted;
  }
}

.u-size {
  &--p {
    font-size: 1rem;
  }

  &--h1 {
    @extend h1;
  }

  &--h2 {
    @extend h2;
  }

  &--h3 {
    @extend h3;
  }

  &--h4 {
    @extend h4;
  }

  &--h5 {
    @extend h5;
  }

  &--h6 {
    @extend h6;
  }
}

.u-weight {
  &--light {
    font-weight: 300;
  }

  &--regular {
    font-weight: 400;
  }

  &--medium {
    font-weight: 500;
  }
}

.u-opacity {
  &--half {
    opacity: 0.5;
  }
}

.u-title-grouping {
  line-height: 1rem;
}

.u-subtitle {
  text-transform: uppercase;
  letter-spacing: 1px;
  display: block;
}

.u-text-cols {
  columns: 2 200px;
  column-gap: 30px;
}
