/* ==========================================================================
   #GRID
   ========================================================================== */

.o-grid {
  display: grid;
  grid-gap: 30px;
  grid-template-columns: 1fr;

  @include bp(small) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.o-grid--2 {
  @include bp(small) {
    grid-template-columns: 1fr 1fr;
  }
}

.o-grid--1 {
  @include bp(small) {
    grid-template-columns: 1fr;
  }
}

.o-grid__body-right {
  @include bp(small) {
    grid-column: 2 / 4;
  }
}

.o-grid__second-col {
  @include bp(small) {
    grid-column: 2 / 3;
  }
}

.o-grid__third-col {
  @include bp(small) {
    grid-column: 3 / 4;
  }
}

.o-grid--stagger {
  @include bp(small) {
    & > *:nth-child(odd) {
      margin-top: 15vh;
    }
  }
}
