/* ==========================================================================
   #BLOCKQUOTES
   ========================================================================== */

blockquote {
  @extend .u-size--h1;
  @extend .u-weight--light;
  quotes: '“' '”' '‘' '’';

  p:first-child {
    &:before {
      content: open-quote;
    }
  }

  p:last-child {
    &:after {
      content: close-quote;
    }
  }

  &:before {
    background: $color-pink;
    display: block;
    content: '';
    height: 3px;
    width: 50%;
    max-width: 400px;
    margin-bottom: 1rem;
  }
}
