/* ==========================================================================
   #VIDEOS
   ========================================================================== */

/**
 * 1. Fluid images for responsive purposes.
 */

video {
  max-width: 100%; /* [1] */
}

/**
 * 1. If a `width` and/or `height` attribute have been explicitly defined, let’s
 *    not make the image fluid.
 */

video[width],
video[height] {
  max-width: none;
}

video:hover,
video:focus,
video:active {
  outline: none;
}
