/* ==========================================================================
   #MAIN NAV
   ========================================================================== */

.c-main-nav {
  left: calc(100% - 85px);
  list-style-type: none;
  position: absolute;
  z-index: 10;
  padding: 0;
  margin: 0;
  top: 2rem;

  @include bp(medium) {
    transform: translateY(calc(-100% - 30px));
    position: fixed;
    top: 100%;
    left: 30px;
  }
}

.c-main-nav__item {
  color: $color-pink;
  text-transform: uppercase;
  margin-bottom: 0.5rem;

  @include bp(medium) {
    margin-bottom: 1rem;
  }

  &:hover .c-main-nav__dropdown {
    display: flex;
  }
}

.c-main-nav__link {
  transition: $global-transition;
  text-decoration: none;
  background: transparent;
  position: relative;
  color: $color-pink;
  padding: 6px 0;

  @extend .u-link-highlight;

  &:hover {
    padding: 6px;
    color: $color-black;
  }
}

.current-menu-item .c-main-nav__link {
  color: $color-pink--dark;

  &:before {
    content: '\2014';
    margin-right: 5px;
  }
}

.c-main-nav__dropdown {
  display: none;
  flex-direction: column;
  margin: 0;
  position: absolute;
}

.c-main-nav__subitem {
  list-style: none;
  margin: 0;
}

.c-main-nav__sublink {
  background: $color-white;
  border-bottom: 1px solid $color-border;
  display: block;
  padding: 0.5em;
  text-decoration: none;
}

.current-menu-item .c-main-nav__sublink {
  border-bottom-color: $color-black;
}
