/* ==========================================================================
   #REEL PAGES
   ========================================================================== */

.c-reel {
  min-height: 100vh;

  &__frame {
    overflow-x: hidden;
    white-space: nowrap;
    padding: 225px 0 50px;

    @include bp(medium) {
      padding: 135px 0;
    }
  }

  &__item {
    position: relative;
    display: inline-block;
    width: 100vw;
    vertical-align: top;
    padding: 0 30px;
  }

  &__container {
    margin: 0 auto;
    max-width: 960px;
    width: 100%;
  }

  &__video {
    @extend .u-animated;
  }

  &__thumbs {
    display: grid;
    padding: 0.5rem 30px;
    background: $color-black;
    transition: $global-transition;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 1rem;

    @include bp(small) {
      z-index: 10;
      display: flex;
      flex-direction: column;
      overflow-y: scroll;
      max-width: 250px;
      height: 100vh;
      width: 50%;
      transform: translateX(60%);
      position: absolute;
      top: 0;
      right: 0;
    }

    &:hover,
    &.js-display-thumbs {
      transform: translateX(0);

      .c-reel__thumb:not(.active) {
        opacity: 1;
      }
    }
  }

  &__thumb {
    transition: $global-transition;
    opacity: 0.35;
    margin: 0.5rem 0;
  }

  &__thumb--archive-link {
    position: relative;
    padding-top: 56.25%;
    background: #464646;
  }

  &__archive-link {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    text-decoration: none;
    justify-content: center;
  }
}

.active {
  .c-reel__video {
    @extend .u-fade-scale-up;
  }

  &.c-reel__thumb {
    opacity: 0.35;
  }
}
