/* ==========================================================================
   #ANIMATIONS
   ========================================================================== */

@keyframes bounce {
  0%,
  20%,
  53%,
  80%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translateZ(0);
  }

  40%,
  43% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -20px, 0);
  }

  70% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -10px, 0);
  }

  90% {
    transform: translate3d(0, -2px, 0);
  }
}

@keyframes bounce-right {
  0%,
  20%,
  53%,
  80%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translateZ(0);
  }

  40%,
  43% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(-15px, 0, 0);
  }

  70% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(-7px, 0, 0);
  }

  90% {
    transform: translate3d(-2px, 0, 0);
  }
}

@keyframes fade-scale-up {
  from {
    transition-timing-function: ease-in;
    transform: scale(0.98) translate(2vh, -2vw);
    opacity: 0;
  }

  to {
    transition-timing-function: ease-out;
    transform: scale(1) translate(0, 0);
    opacity: 1;
  }
}

@keyframes fade-slide-up {
  from {
    transition-timing-function: ease-in;
    transform: translateY(40px);
    opacity: 0;
  }

  to {
    transition-timing-function: ease-out;
    transform: translateY(0);
    opacity: 1;
  }
}

.u-animated {
  animation-duration: 2s;
  animation-fill-mode: both;

  &--infinite {
    animation-iteration-count: infinite;
  }
}

.u-bounce {
  animation-name: bounce;
  transform-origin: center bottom;

  &--right {
    animation-name: bounce-right;
    transform-origin: center bottom;
  }
}

.u-fade-scale-up {
  animation-name: fade-scale-up;
  transform-origin: top right;
}

.u-fade-slide-up {
  animation-duration: 1.25s;
  animation-name: fade-slide-up;
  transform-origin: center bottom;
}
