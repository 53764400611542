/* ==========================================================================
   #LINK CLASSES
   ========================================================================== */

.u-link-highlight {
  &:after {
    @extend .u-pos-cover;
    border-radius: $global-radius;
    transition: $global-transition;
    margin: $global-radius * 2;
    display: block;
    content: '';
    z-index: -1;
  }

  &:hover:after {
    background: $color-pink;
    margin: 0;
  }
}
