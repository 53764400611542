/* ==========================================================================
   #FLEX CLASSES
   ========================================================================== */

.u-flex {
  display: flex;

  &--vcenter {
    flex-direction: column;
    justify-content: center;
  }
}
