/* ==========================================================================
   #SLIDER
   ========================================================================== */

.c-slider {
  width: 100%;
  min-height: 100vh;

  &__slides {
    margin: 0;
  }

  &__slide {
    margin: 0;
    position: relative;
    display: none;
  }

  &__video {
    @extend .u-animated;
    object-fit: cover;
    height: 100vh;
    width: 100vw;
  }

  &__title {
    @extend .u-animated;
  }

  &__detail {
    @extend .u-pos-cover;
    transition: background-image 1s ease-in;
    z-index: 1;
    background-image: radial-gradient(
      ellipse at 90% 20%,
      rgba(0, 0, 0, 0),
      rgb(0, 0, 0) 115vw
    );
  }

  &__body {
    height: 100vh;
  }

  &__campaign {
    margin: 0;

    & > span {
      margin-right: 1px;
      will-change: transform;
      display: inline-block;
      white-space: pre;
    }

    & > p:last-child {
      margin-bottom: 0;
    }
  }

  &__skip {
    @extend .u-link-highlight;
    transition: $global-transition;
    position: absolute;
    display: block;
    bottom: 30px;
    right: 30px;
    z-index: 10;
    padding: 10px;
    border-radius: 50%;

    &:after {
      border-radius: 50% !important;
    }
  }
}

.c-slider__slide--active {
  display: block;

  .c-slider__video {
    @extend .u-fade-scale-up;
  }

  .c-slider__title {
    @extend .u-fade-slide-up;

    &:nth-child(2) {
      animation-delay: 0.3s;
    }
  }
}
